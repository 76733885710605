<template>
  <b-row align-h="center">
    <b-col
      md="3"
      class="mt-5"
    >
      <b-card>
        <div class="text-center mb-2">
          <b-img
            :src="require('@/assets/images/logo/muslim-do-logo.png')"
            width="150em"
          />
        </div>

        <div class="my-1">
          <h3 class="mb-1">Forgot Password?
            <feather-icon
              icon="LockIcon"
              size="20"
            />
          </h3>

          <span>Enter your email and we'll send you instructions to reset your password</span>
        </div>

        <validation-observer #default="{handleSubmit}">
          <b-form @submit.prevent>
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  placeholder="email@example.com"
                  :state="getValidationState(validationContext)"
                />
                <b-form-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-feedback>
              </b-form-group>
            </validation-provider>
            <submit-button
              btn-class="btn-block"
              :handle-submit="handleSubmit"
              :submit="resetPassword"
              submit-text="Send reset link"
            />

            <div class="text-center mt-1">
              <b-link @click="$router.back()">
                &lt; Back to login
              </b-link>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import formValidation from '@/@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'

export default {
  name: 'ResetPassword',
  components: { SubmitButton },
  data() {
    return {
      email: '',
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const { successfulOperationAlert } = handleAlerts()
    return {
      getValidationState,
      successfulOperationAlert,

    }
  },
  methods: {
    resetPassword() {
      return this.$portalUsers.post('v1/password/request/reset', { email: this.email }).then(() => {
        this.successfulOperationAlert('Reset password email is sent successfully. Please check your email')
      })
    },
  },
}
</script>
<style lang="scss">

</style>
